// extracted by mini-css-extract-plugin
export var blurb = "PlasmicTalkPreview-module--blurb--61c1d";
export var date = "PlasmicTalkPreview-module--date--fcf77";
export var freeBox___4XrJ2 = "PlasmicTalkPreview-module--freeBox___4XrJ2--5e7cf";
export var freeBox__re1TW = "PlasmicTalkPreview-module--freeBox__re1TW--5df5f";
export var freeBox__tDRzi = "PlasmicTalkPreview-module--freeBox__tDRzi--37e24";
export var img__tWyDj = "PlasmicTalkPreview-module--img__tWyDj--c0f68";
export var root = "PlasmicTalkPreview-module--root--58fdf";
export var slotTargetDate = "PlasmicTalkPreview-module--slotTargetDate--39e5f";
export var slotTargetSummary = "PlasmicTalkPreview-module--slotTargetSummary--9be04";
export var slotTargetTitle = "PlasmicTalkPreview-module--slotTargetTitle--2f18f";
export var title = "PlasmicTalkPreview-module--title--a0187";