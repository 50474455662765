// extracted by mini-css-extract-plugin
export var allTalks = "PlasmicTalks-module--allTalks--c20af";
export var back = "PlasmicTalks-module--back--a9196";
export var copyright = "PlasmicTalks-module--copyright--23b3c";
export var freeBox__bD0Ie = "PlasmicTalks-module--freeBox__bD0Ie--edc83";
export var freeBox__o8Ejt = "PlasmicTalks-module--freeBox__o8Ejt--0d652";
export var freeBox__rcgr = "PlasmicTalks-module--freeBox__rcgr--68cfc";
export var h1 = "PlasmicTalks-module--h1--19dee";
export var img__eW72T = "PlasmicTalks-module--img__eW72T--3f1d1";
export var img__oQWy9 = "PlasmicTalks-module--img__oQWy9--d11b0";
export var img__sMw5B = "PlasmicTalks-module--img__sMw5B--a7f11";
export var nlpsummit = "PlasmicTalks-module--nlpsummit--6c9bb";
export var p = "PlasmicTalks-module--p--6b220";
export var root = "PlasmicTalks-module--root--66655";
export var sealights = "PlasmicTalks-module--sealights--5f90e";
export var text___6PAiv = "PlasmicTalks-module--text___6PAiv--f6766";
export var text___6SdgW = "PlasmicTalks-module--text___6SdgW--fcd01";
export var text__rjl = "PlasmicTalks-module--text__rjl--a5097";
export var text__x1779 = "PlasmicTalks-module--text__x1779--d1b88";
export var text__zbAfr = "PlasmicTalks-module--text__zbAfr--c8e05";
export var worldfestival = "PlasmicTalks-module--worldfestival--b42b8";